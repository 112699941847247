.search {
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
}

.search-result {
  margin-bottom: 30px;
  font-size: 1rem;

  @include media-breakpoint-up(lg) {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .highlight {
    background-image: $light-gradient;
    text-decoration: underline;
  }
}

.search-result__title {
  margin-bottom: 6px;
}

.search-result__description {
  color: map-get($colors, 'text-primary');
  font-size: 0.875rem;

  @include media-breakpoint-up(lg) {
    font-size: 16px;
    line-height: 20px;
  }
}

.search-form {
  margin-bottom: 60px;
  position: relative;
}

.search-not-found {
  ul {
    font-weight: 300;
    font-size: 18px;
    line-height: 26px;
    color: map-get($colors, 'text-primary');
  }

  li {
    margin-bottom: 1rem;
  }
}
