.video {
  position: relative;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: $border-radius;
  box-shadow: $shadow;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: $border-radius;
    background: linear-gradient(158.42deg, rgba(237, 24, 74, 0.2) -7.82%, rgba(238, 133, 73, 0.2) 105.71%);
    z-index: -2;
    transform: rotate(-3deg);
  }

  &::after {
    background-image: url("/assets/images/dash-rect.png");
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    position: absolute;
    top: 0;
    left: 0.25rem;
    width: 100%;
    height: 100%;
    z-index: -1;
    transform: rotate(-5deg);
  }
}

.video__poster {
  width: 100%;
  border-radius: $border-radius;
  display: block;
}

.video__body {
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(137.65deg, rgba(237, 24, 74, 0.3) -7.58%, rgba(132, 117, 226, 0.3) 89.26%);
    border-radius: $border-radius;
  }
}

.video__play {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .button {
    border-color: transparent;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }
}
