.modal,
.modal__overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.modal {
  z-index: 50;
  display: flex;

  &[aria-hidden='true'] {
    display: none;
  }
}

.modal_media {
  .modal__body {
    max-width: 900px;
    width: 100%;
    height: auto;
    background-color: transparent;

    @include media-breakpoint-up(lg) {
      padding: 0;
      max-width: 900px;
    }

    iframe {
      border-radius: $border-radius;
    }
  }

  .modal__close {
    color: $button-color;
    background-image: none;
    border-color: currentColor;
    box-shadow: none;

    &:hover,
    &:focus,
    &:active {
      box-shadow: $button-box-shadow;
      background-image: $dark-gradient;
      border-color: transparent;
      color: $button-color;
    }

    @include media-breakpoint-up(lg) {
      top: 0.5rem;
      right: 0.5rem;
    }
  }
}

.modal__overlay {
  background-color: rgba(43, 46, 56, 0.4);
  animation: fade-in 200ms both;
}

.modal__body {
  margin: auto;
  z-index: 50;
  position: relative;
  background-color: white;
  width: 100%;
  height: 100%;
  border-radius: 0;
  animation: fade-in 400ms 200ms both, slide-up 400ms 200ms both;

  @include media-breakpoint-up(md) {
    width: auto;
    height: auto;
    max-width: 510px;
    padding: 2rem;
    border-radius: $border-radius;
  }
}

.modal__close {
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.07);
  background-image: none;
  box-shadow: none;
  color: #bdbdbd;

  &:hover,
  &:focus,
  &:active {
    color: white;
    background-image: $dark-gradient;
    border-color: transparent;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
}

@keyframes slide-up {
  from {
    transform: translateY(10%);
  }
}
