h1,
.headline-1 {
  font-size: map-deep-get($headings-mobile, 'h1', 'size');
  font-weight: map-deep-get($headings-mobile, 'h1', 'weight');
  line-height: map-deep-get($headings-mobile, 'h1', 'line-height');
}

h2,
.headline-2 {
  font-size: map-deep-get($headings-mobile, 'h2', 'size');
  font-weight: map-deep-get($headings-mobile, 'h2', 'weight');
  line-height: map-deep-get($headings-mobile, 'h2', 'line-height');
}

h3,
.headline-3 {
  font-size: map-deep-get($headings-mobile, 'h3', 'size');
  font-weight: map-deep-get($headings-mobile, 'h3', 'weight');
  line-height: map-deep-get($headings-mobile, 'h3', 'line-height');
}

h4,
.headline-4 {
  font-size: map-deep-get($headings-mobile, 'h4', 'size');
  font-weight: map-deep-get($headings-mobile, 'h4', 'weight');
  line-height: map-deep-get($headings-mobile, 'h4', 'line-height');
}

h5,
.headline-5 {
  font-size: map-deep-get($headings-mobile, 'h5', 'size');
  font-weight: map-deep-get($headings-mobile, 'h5', 'weight');
  line-height: map-deep-get($headings-mobile, 'h5', 'line-height');
}

.subtitle-1 {
  font-size: map-deep-get($headings-mobile, 'subtitle-1', 'size');
  font-weight: map-deep-get($headings-mobile, 'subtitle-1', 'weight');
  line-height: map-deep-get($headings-mobile, 'subtitle-1', 'line-height');
}

.subtitle-2 {
  font-size: map-deep-get($headings-mobile, 'subtitle-2', 'size');
  font-weight: map-deep-get($headings-mobile, 'subtitle-2', 'weight');
  line-height: map-deep-get($headings-mobile, 'subtitle-2', 'line-height');
}

.body-1 {
  font-size: map-deep-get($headings-mobile, 'body-1', 'size');
  font-weight: map-deep-get($headings-mobile, 'body-1', 'weight');
  line-height: map-deep-get($headings-mobile, 'body-1', 'line-height');
}

.body-2 {
  font-size: map-deep-get($headings-mobile, 'body-2', 'size');
  font-weight: map-deep-get($headings-mobile, 'body-2', 'weight');
  line-height: map-deep-get($headings-mobile, 'body-2', 'line-height');
}

@include media-breakpoint-up(lg) {
  h1,
  .headline-1 {
    font-size: map-deep-get($headings-desktop, 'h1', 'size');
    font-weight: map-deep-get($headings-desktop, 'h1', 'weight');
    line-height: map-deep-get($headings-desktop, 'h1', 'line-height');
  }

  h2,
  .headline-2 {
    font-size: map-deep-get($headings-desktop, 'h2', 'size');
    font-weight: map-deep-get($headings-desktop, 'h2', 'weight');
    line-height: map-deep-get($headings-desktop, 'h2', 'line-height');
  }

  h3,
  .headline-3 {
    font-size: map-deep-get($headings-desktop, 'h3', 'size');
    font-weight: map-deep-get($headings-desktop, 'h3', 'weight');
    line-height: map-deep-get($headings-desktop, 'h3', 'line-height');
  }

  h4,
  .headline-4 {
    font-size: map-deep-get($headings-desktop, 'h4', 'size');
    font-weight: map-deep-get($headings-desktop, 'h4', 'weight');
    line-height: map-deep-get($headings-desktop, 'h4', 'line-height');
  }

  h5,
  .headline-5 {
    font-size: map-deep-get($headings-desktop, 'h5', 'size');
    font-weight: map-deep-get($headings-desktop, 'h5', 'weight');
    line-height: map-deep-get($headings-desktop, 'h5', 'line-height');
  }

  .subtitle-1 {
    font-size: map-deep-get($headings-desktop, 'subtitle-1', 'size');
    font-weight: map-deep-get($headings-desktop, 'subtitle-1', 'weight');
    line-height: map-deep-get($headings-desktop, 'subtitle-1', 'line-height');
  }

  .subtitle-2 {
    font-size: map-deep-get($headings-desktop, 'subtitle-2', 'size');
    font-weight: map-deep-get($headings-desktop, 'subtitle-2', 'weight');
    line-height: map-deep-get($headings-desktop, 'subtitle-2', 'line-height');
  }

  .body-1 {
    font-size: map-deep-get($headings-desktop, 'body-1', 'size');
    font-weight: map-deep-get($headings-desktop, 'body-1', 'weight');
    line-height: map-deep-get($headings-desktop, 'body-1', 'line-height');
  }

  .body-2 {
    font-size: map-deep-get($headings-desktop, 'body-2', 'size');
    font-weight: map-deep-get($headings-desktop, 'body-2', 'weight');
    line-height: map-deep-get($headings-desktop, 'body-2', 'line-height');
  }
}
