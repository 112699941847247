.card {
  border-radius: $border-radius;
  box-shadow: $shadow;
  background: white;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
}

.card__content {
  padding: 1rem;
  flex: 1 1 auto;

}

.card__actions {
  padding: 1rem 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card__title {
  margin-bottom: 1rem;
  font-size: 1.125rem;
  font-weight: 500;

  &:last-child {
    margin-bottom: 0;
  }
}

.card__text {
  font-size: 14px;

  &:last-child {
    margin-bottom: 0;
  }
}
