.divider {
  width: 0;
  height: inherit;
  display: inline-flex;
  border: solid;
  border-color: map-get($colors, 'divider');
  border-width: 0 1px 0 0;
  vertical-align: text-bottom;
}

@each $breakpoint in map-keys($grid-breakpoints) {
  $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    .divider#{$infix}-horizontal {
      width: 100%;
      height: 0;
      display: block;
      border-width: 1px 0 0;
    }
  }
}
