// @import '~normalize.css';
// @import '~aos/dist/aos.css';
@import 'client/vars';
@import 'client/functions';
@import 'client/mixins/index';
@import 'client/grid';
@import 'client/button';
@import 'client/card';
@import 'client/divider';
@import 'client/form-elements';
@import 'client/common';
@import 'client/layout';
@import 'client/lists';
@import 'client/video';
@import 'client/page-header';
@import 'client/modal';
@import 'client/search';
@import 'client/stepper';
@import 'client/constructor';

// utilities
@import 'client/utilities/display';
@import 'client/utilities/flex';
@import 'client/utilities/text';
@import 'client/typogrphy';

.admin {
  background: #222;
  color: #eee;
  padding: 10px 0;

  a {
    color: #eee;
    font-weight: 500;
  }

  .container {
    display: flex;
    justify-content: space-between;
  }
}
