.numbered-list {
  list-style: none;
  counter-reset: counter;
  padding: 0;
  margin: 0;

  li:not(:nth-last-child(1)) {
    margin-bottom: 1rem;
  }

  li::before {
    content: counter(counter);
    counter-increment: counter;
    margin-right: 6px;
    font-weight: 700;
    color: map-get($colors, 'primary');
  }

  li:last-child {
    margin-bottom: 0;
  }
}

.check-list {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: left;

  li {
    margin-top: 1rem;

    &:first-of-type {
      margin-top: 0;
    }

    &::before {
      content: "done";
      font-size: inherit;
      margin-right: 0.5rem;
      color: map-get($colors, 'primary');

      @extend .icon;
    }
  }
}
