.field {
  margin: 1rem 0;
  display: block;
  width: 100%;

  select,
  .input,
  .textarea {
    border: 1px solid map-get($colors, divider);
    border-radius: 30px;
    width: 100%;
    min-height: 48px;
    padding: 1rem 1.5rem;
    display: block;
    outline: 0;
    position: relative;
    font-family: inherit;

    &:focus {
      border-color: map-get($colors, primary);
    }

    &::placeholder {
      color: map-get($colors, placeholder);
    }
  }

  .textarea {
    min-height: 160px;
    overflow: auto;
    resize: vertical;
  }

  select {
    appearance: none;
    &::-ms-expand {
      display: none;
    }
  }
}

.field_search {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  height: 50px;
  background: white;
  padding-left: 1.5rem;
  padding-right: 1rem;
  color: rgba(0, 0, 0, 0.3);
  border: 1px solid map-get($colors, divider);
  border-radius: 150px;

  &:focus-within {
    border-color: map-get($colors, primary);
  }

  @include media-breakpoint-up(lg) {
    height: 70px;
  }

  .icon {
    margin-left: 1rem;
    padding: 0 0.5rem;
    font-size: 1.25rem;

    @include media-breakpoint-up(lg) {
      font-size: 2rem;
    }
  }

  input[type="search"] {
    text-overflow: ellipsis;
    white-space: nowrap;
    border: none;
    outline: 0;
    flex: 1 1 auto;
    font-size: 1rem;
    font-family: inherit;

    @include media-breakpoint-up(lg) {
      font-size: 1.375rem;
    }

    &::placeholder {
      color: rgba(0, 0, 0, 0.3);
    }
  }
}

.checkbox {
  margin-bottom: 0.75rem;
}

.custom-checkbox>input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-checkbox>span {
  display: inline-flex;
  align-items: center;
  user-select: none;
}

.custom-checkbox>span::before {
  content: '';
  display: inline-block;
  width: 1.125rem;
  height: 1.125rem;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #adb5bd;
  border-radius: 0.25rem;
  margin-right: 0.5rem;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 60% 60%;
}

.custom-checkbox>input:checked+span::before {
  border-color: #e23a2e;
  background-image: url("/assets/images/done.png");
}

.custom-checkbox>input:not(:disabled):not(:checked)+span:hover::before {
  border-color: #eb7870;
}
/* стили для активного состояния чекбокса (при нажатии на него) */
.custom-checkbox>input:not(:disabled):active+span::before {
  border-color: #ee564a;
}
/* стили для чекбокса, находящегося в состоянии disabled */
.custom-checkbox>input:disabled+span::before {
  background-color: #e9ecef;
}

.custom-radio>input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

/* для элемента label связанного с .custom-radio */
.custom-radio>span {
  display: inline-flex;
  align-items: center;
  user-select: none;
}

/* создание в label псевдоэлемента  before со следующими стилями */
.custom-radio>span::before {
  content: '';
  display: inline-block;
  width: 1em;
  height: 1em;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #adb5bd;
  border-radius: 50%;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 70% 70%;
}

/* стили при наведении курсора на радио */
.custom-radio>input:not(:disabled):not(:checked)+span:hover::before {
  border-color: #8d8d8d;
}

/* стили для активной радиокнопки (при нажатии на неё) */
.custom-radio>input:not(:disabled):active+span::before {
  background-color: #f5f5f5;
  border-color: #adadad;
}


/* стили для радиокнопки, находящейся в фокусе и не находящейся в состоянии checked */
.custom-radio>input:focus:not(:checked)+span::before {
  border-color: #adadad;
}

/* стили для радиокнопки, находящейся в состоянии checked */
.custom-radio>input:checked+span::before {
  border-color: #ee564a;
  background-image: url("/assets/images/radio.png");
}

/* стили для радиокнопки, находящейся в состоянии disabled */
.custom-radio>input:disabled+span::before {
  background-color: #e9ecef;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  background-image: url("/assets/images/calendar.svg");
  background-repeat: no-repeat;
  background-position: 100% center;
  background-size: 18px;
  color: #8d8d8d;
  cursor: pointer;
}
