$border-radius: 10px;
$navbar-height: 4.5rem;

$shadow: 2px 2px 20px rgba(0, 0, 0, 0.15);
$extra-shadow: 2px 2px 20px rgba(0, 0, 0, 0.4);

/* grid */
$grid-columns: 12;
$grid-gutter-width: 2rem;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1084px,
  xl: 1264px
);

$container-max-widths: (
  lg: 1172px
);

$colors: (
  primary: #ee564a,
  secondary: #ee8549,
  divider: rgba(0, 0, 0, 0.1),
  text-primary: #3e4245,
  text-secondary: #727272,
  placeholder: rgba(62, 66, 69, 0.6)
);

$light-gradient: linear-gradient(152.01deg, rgba(237, 24, 74, 0.1) -7.82%, rgba(238, 133, 73, 0.1) 105.71%);
$dark-gradient: linear-gradient(174.55deg, #ed184a -7.82%, map-get($colors, 'secondary') 105.71%);

$headings-desktop: (
  "h1": (
    "size": 3.125rem, /* 50px */
    "weight": 500,
    "line-height": 1.2
  ),
  "h2": (
    "size": 2.5rem, /* 40px */
    "weight": 500,
    "line-height": 1.2
  ),
  "h3": (
    "size": 1.875rem, /* 30px */
    "weight": 500,
    "line-height": 1.2
  ),
  "h4": (
    "size": 1.25rem, /* 20px */
    "weight": 500,
    "line-height": 1.2
  ),
  "h5": (
    "size": 1.125rem, /* 18px */
    "weight": 500,
    "line-height": 1.3
  ),
  "subtitle-1": (
    "size": 1.375rem, /* 22px */
    "weight": 400,
    "line-height": 1.3
  ),
  "subtitle-2": (
    "size": 1.125rem, /* 18px */
    "weight": 400,
    "line-height": 1.4
  ),
  "body-1": (
    "size": 1rem, /* 16px */
    "weight": 400,
    "line-height": 1.4
  ),
  "body-2": (
    "size": 0.875rem, /* 14px */
    "weight": 400,
    "line-height": 1.4
  )
);

$headings-mobile: (
  "h1": (
    "size": 2rem, /* 32px */
    "weight": 500,
    "line-height": 1.2
  ),
  "h2": (
    "size": 1.75rem, /* 28px */
    "weight": 500,
    "line-height": 1.2
  ),
  "h3": (
    "size": 1.5rem, /* 24px */
    "weight": 500,
    "line-height": 1.2
  ),
  "h4": (
    "size": 1.125rem, /* 20px */
    "weight": 500,
    "line-height": 1.4
  ),
  "h5": (
    "size": 0.875rem, /* 14px */
    "weight": 500,
    "line-height": 1.3
  ),
  "subtitle-1": (
    "size": 1.125rem, /* 18px */
    "weight": 400,
    "line-height": 1.3
  ),
  "subtitle-2": (
    "size": 1rem, /* 16px */
    "weight": 400,
    "line-height": 1.4
  ),
  "body-1": (
    "size": 0.875rem, /* 14px */
    "weight": 400,
    "line-height": 1.4
  ),
  "body-2": (
    "size": 0.75rem, /* 12px */
    "weight": 400,
    "line-height": 1.4
  )
);

// <-- Buttons -->

$button-color: white;
$button-outlined-bg: white;
$button-outlined-color: map-get($colors, primary);
$button-box-shadow: 2px 4px 10px rgba(238, 133, 73, 0.4);

%gradient-effect {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  border-radius: inherit;
  background: $dark-gradient;
}

@mixin text-ellipsis {
  white-space:   nowrap;
  text-overflow: ellipsis;
  overflow-x:    hidden;
}
