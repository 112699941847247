/* header */
.header {
  color: map-get($colors, 'text-secondary');
  margin-bottom: 1rem;
}

.navbar {
  height: $navbar-height;
  display: flex;
  align-items: center;
  border-bottom: 1px solid map-get($colors, 'divider');
  margin-bottom: 5rem;

  @include media-breakpoint-up(lg) {
    border-bottom: none;
  }
}

.navbar-menu {
  margin: 0;
  padding: 0 1rem;
  list-style: none;
  display: flex;
  position: fixed;
  z-index: 2;
  top: $navbar-height;
  left: 0;
  transform: scaleY(0);

  & > .navbar-item {
    padding: 1rem 0;
    font-size: 1.25rem;
  }

  @include media-breakpoint-up(lg) {
    transform: none;
    opacity: 1;
    position: static;
    flex-flow: row nowrap;
    align-items: center;
    flex: 1 1 100%;

    & > .navbar-item {
      font-size: 1rem;
      padding: 0.5rem;
    }
  }
}

.navbar-submenu {
  padding: 0;
  margin-top: 2rem;
  list-style: none;
  max-width: 240px;
  display: none;

  & > .navbar-item {
    padding: 0.5rem 0;
    font-size: 1.125rem;
  }

  &::after {
    content: "";
    display: inline-block;
    width: 40px;
    height: 1px;
    background: map_get($colors, "divider");
  }

  @include media-breakpoint-up(lg) {
    background: white;
    box-shadow: $shadow;
    border-radius: 5px;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 100%;
    font-size: map-deep-get($headings-desktop, "body-2", "size");
    margin: 0;
    padding: 0.25rem 0.5rem;
    max-width: none;

    & > .navbar-item {
      font-size: 0.875rem;
      white-space: nowrap;
      padding: 0.25rem 0;
    }

    &::after {
      display: none;
    }
  }
}

.navbar-item {
  outline: 0;
  position: relative;
  font-weight: 500;
  color: #5a5050;

  & > a {
    color: inherit;
    text-decoration: none;
    background: none;

    &:hover,
    &:focus,
    &:active {
      color: map-get($colors, 'primary');
    }
  }

  &.active > a {
    font-weight: 500;
    color: map-get($colors, 'primary');
  }

  &.expanded,
  &:hover {
    & > a {
      color: map-get($colors, 'primary');
    }
  }

  &.expanded {
    .navbar-submenu {
      display: block;
    }
  }

  @include media-breakpoint-up(lg) {
    &:hover {
      .navbar-submenu {
        display: block;
      }
    }
  }
}

.navbar-brand {
  width: 105px;
  display: block;
  flex: 0 0 auto;

  @include media-breakpoint-up(lg) {
    margin-top: 0.5rem;
  }

  img {
    width: 100%;
    height: auto;
    display: block;
  }
}

.navbar-toggle {
  border: 0;
  padding: 0;
  outline: 0;
  background: none;
  margin-left: auto;

  @include media-breakpoint-up(lg) {
    display: none;
  }

  [data-target="close"] {
    display: none;
  }
}

.navbar-phone {
  display: none;
  flex-shrink: 0;

  @include media-breakpoint-up(lg) {
    display: inline-flex;
    margin-left: auto;
  }
}

.navbar-button {
  display: none;
  flex-shrink: 0;

  @include media-breakpoint-up(lg) {
    display: inline-flex;
  }
}

.navbar-menu-expanded {
  overflow: hidden;

  .navbar {
    border-color: white;
    background: white;
    position: fixed;
    z-index: 2;
    top: 0;
  }

  .navbar-brand {
    display: none;
  }

  .navbar-phone,
  .navbar-button {
    display: inline-flex;
  }

  .navbar-button {
    order: -1;
  }

  .navbar-toggle {
    color: #bdbdbd;

    &:active,
    &:focus {
      color: inherit;
    }

    [data-target="open"] {
      display: none;
    }

    [data-target="close"] {
      display: inline;
    }
  }

  .navbar-menu {
    overflow: auto;
    flex-flow: column nowrap;
    align-items: center;
    text-align: center;
    background: white;
    width: 100%;
    height: 100%;
    max-height: calc(100vh - #{$navbar-height});
    transition: transform 0.25s ease-in-out;
    transform-origin: 0 0;
    transform: scaleY(1);
  }
}

.subnavbar-item {
  list-style-type: none;
  padding-left: 0rem;
  padding-top: 0.5rem;
  font-size: 0.875rem;

    .navbar-item {
      font-weight: normal;
    }

    @include media-breakpoint-up (lg) {
      padding-left: 1rem;
      padding-top: 0rem;
    }
}


// <-- footer -->
.footer {
  background: #f8f8fa;
  color: map-get($colors, 'text-secondary');
  font-size: 0.75rem;
  padding-top: 2rem;

  @include media-breakpoint-up(lg) {
    padding-top: 100px;
    font-size: map-deep-get($headings-desktop, 'body-2', 'size');

    .divider {
      margin: 0 1rem;
    }
  }
}

.footer__title {
  margin-bottom: 1rem;
  margin-top: 0;
  font-size: 1rem;
  font-weight: 500;
  color: map-get($colors, 'primary');

  @include media-breakpoint-up(lg) {
    font-size: 1.125rem;
  }
}

.footer__items {
  padding: 0;
  list-style: none;
  margin: 0 0 50px;
}

.footer__item {
  margin: 1rem 0;

  a:not(.button) {
    color: inherit;

    &:hover {
      color: map-get($colors, 'primary');
    }
  }
}
// </-- footer -->
