@each $breakpoint in map-keys($grid-breakpoints) {
  $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

  @include media-breakpoint-up($breakpoint) {
    .d#{$infix}-none { display: none !important; }
    .d#{$infix}-inline { display: inline !important; }
    .d#{$infix}-inline-block { display: inline-block !important; }
    .d#{$infix}-block { display: block !important; }
    .d#{$infix}-flex { display: flex !important; }
    .d#{$infix}-inline-flex { display: inline-flex !important; }
  }
}
