*,
*::after,
*::before {
  box-sizing: border-box;

  @media (prefers-reduced-motion: reduce) {
    transition-duration: 0s !important;
    animation: none !important;
  }
}

html {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  font-family: "Gotham Pro", Arial, sans-serif;
  color: map-get($colors, 'text-primary');
}

body {
  min-width: 360px;
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  margin: 0;
}

main {
  flex: 1 0 auto;
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
figure {
  margin: 0;
}

button {
  cursor: pointer;
}

a,
.primary {
  color: map-get($colors, 'primary');
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

p {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

b,
strong {
  font-weight: 600;
}

/*
* Собранный сет иконок через icomoon: arrow_forward, arrow_back, play_arrow, close, expand_more, instagram, menu, telegram, vk...
* Пример использования: <span class="icon">arrow_forward<span>
*/
.icon {
  $sizes: (
    "xs": 10px,
    "sm": 14px,
    "md": 18px,
    "lg": 24px,
    "xl": 32px
  );
  /* stylelint-disable-next-line */
  font-family: "UchiProIcons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  line-height: 1 !important;
  letter-spacing: 0 !important;
  font-feature-settings: "liga" !important;
  font-variant-ligatures: discretionary-ligatures !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  vertical-align: middle !important;
  font-size: 24px;

  @each $size in map-keys($sizes) {
    &.icon-#{$size} {
      font-size: map-get($sizes, $size);
    }
  }
}

img {
  max-width: 100%;
  height: auto;
}

fieldset {
  border: none;
}

.section {
  margin-bottom: 3rem;

  @include media-breakpoint-up(md) {
    margin-bottom: 7rem;
  }

  &__title {
    text-align: center;

    @extend .text-underline;
  }

  &__subtitle {
    font-size: 1rem;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 0;

    @include media-breakpoint-up(lg) {
      font-size: 1.125rem;
      margin-top: 2rem;
    }
  }

  &__body,
  &__footer {
    padding-top: 2rem;

    @include media-breakpoint-up(lg) {
      padding-top: 4rem;
    }
  }
}

.actions {
  display: flex;

  > *:not(:first-child) {
    margin-left: 1rem;
  }
}

.table-responsive {
  width: 100%;
  display: block;
  overflow-x: auto;
}

.table {
  border: 1px solid transparent;
  border-collapse: collapse;
  width: 100%;

  th,
  td {
    vertical-align: center;
    padding: 1.5rem 0.5rem;
    border: 1px dashed rgba(0, 0, 0, 0.2);
  }

  thead {
    th,
    td {
      vertical-align: baseline;
    }
  }
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.visually-hidden,
input[type="checkbox"].visually-hidden,
input[type="radio"].visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  white-space: nowrap;
  border: 0;
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
}

.alert {
  background: $light-gradient;
  border-radius: $border-radius;
  padding: 1rem;

  @include media-breakpoint-up(md) {
    padding: 1.5rem;
  }
}

[hidden],
.hidden {
  display: none !important;
}

.aspect-ratio-4-3 {
  width: 100%;
  height: 0;
  padding-bottom: 75%;
  position: relative;

  iframe,
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.run-option {
  text-align: center;
  height: 100%;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;

  .card__actions {
    justify-content: center;
  }
}

.run-option__icon {
  height: 107px;

  img {
    max-height: 100%;
  }
}

.payments {
  display: flex;
  align-items: center;
  margin: 2rem 1rem 1rem;
  justify-content: space-around;
  flex-wrap: wrap;

  img {
    display: inline-block;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-bottom: 1rem;
  }
}

.breadcrumbs {
  margin-right: 2rem;
  font-size: 0,875rem;
  color: #7c7c7c;
  white-space: inherit;

  @include media-breakpoint-up(sm) {
    font-size: 1rem;

    @include text-ellipsis;
  }

  @include media-breakpoint-up(md) {
    font-size: 1.125rem;
  }
}

.cost {
  font-weight: 700;
  font-size: 1.75rem;
  line-height: 1.75rem;
  color: map-get($colors, 'primary');

  @include media-breakpoint-up(md) {
    font-size: 2.25rem;
  }
}

.fullscreen {
  display: none;
  justify-content: flex-end;
  width: auto;
  color: #7c7c7c;
  white-space: nowrap;
  cursor: pointer;

  .title.open, .title.close {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  @include media-breakpoint-up(sm) {
    display: flex;
  }

  &:hover {
    color: #5c5c5c;
  }

  .icon {
    margin-right: 0.5rem;
  }

  .open {
    display: block;
  }
  
  .close {
    display: none;
  }
}

.full-screen {
  .open {
    display: none;
  }
  
  .close {
    display: block;
  }
}

.no-select {
  user-select: none;
}

