.button {
  outline: 0;
  text-decoration: none;
  cursor: pointer;
  vertical-align: middle;
  user-select: none;
  font-size: 0.875rem;
  font-family: inherit;
  border-radius: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  white-space: nowrap;
  padding: 0 1.5rem;
  box-shadow: $button-box-shadow;
  color: $button-color;
  background-color: $button-outlined-bg;
  background-image: $dark-gradient;
  background-repeat: repeat;
  background-origin: border-box;
  border: 1px solid transparent;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    box-shadow: none;
    color: $button-outlined-color;
    background-image: none;
    border-color: currentColor;
  }

  .icon:not(:first-child) {
    margin-left: 1ch;
  }

  .icon:not(:last-child) {
    margin-right: 1ch;
  }
}

.button_lg:not(.button_fab) {
  height: 50px;
  font-size: inherit;
}

.button_sm:not(.button_fab) {
  height: 30px;
}

.button_fab {
  border-radius: 50%;
  flex-shrink: 0;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.15);
  width: 40px;
  padding: 0;

  &:hover,
  &:focus,
  &:active {
    box-shadow: $button-box-shadow;
  }

  &.button_lg {
    width: 50px;
    height: 50px;
  }

  &.button_sm {
    width: 32px;
    height: 32px;
  }
}

.button_outlined {
  color: $button-outlined-color;
  background-image: none;
  border-color: currentColor;
  box-shadow: none;

  &:hover,
  &:focus,
  &:active {
    box-shadow: $button-box-shadow;
    background-image: $dark-gradient;
    border-color: transparent;
    color: $button-color;
  }
}

.button_secondary {
  color: $button-outlined-color;
  background-image: none;

  &:hover,
  &:focus,
  &:active {
    background-image: $dark-gradient;
    border-color: transparent;
    color: $button-color;
  }
}

.button_link {
  font: inherit;
  cursor: pointer;
  padding: 0;
  color: map-get($colors, 'primary');
  text-align: left;
  border: none;
  outline: inherit;
  background-color: transparent;

  &:hover {
    text-decoration: underline;
  }
}

@include media-breakpoint-up(lg) {
  .button {
    font-size: 1rem;
  }

  .button_lg:not(.button_fab) {
    height: 56px;
    font-size: 1.25rem;
    padding: 0 2rem;
  }

  .button_fab {
    &.button_lg {
      height: 56px;
      width: 56px;
    }

    &.button_sm {
      height: 32px;
      width: 32px;
    }
  }
}
