.constructor__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem 1rem;
  margin-bottom: 1rem;
  background-color: inherit;

  @include media-breakpoint-up(md) {
    margin-bottom: 3rem;
  }

  .search-documents {
    width: 100%;
  }

  .search-documents-form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .search-documents-form__title {
      display: none;
      margin-right: 1rem;
      white-space: nowrap;

      @include media-breakpoint-up(sm) {
        display: block;
      }
    }

    .search-documents-form__field {
      max-width: 700px;
    }
  }

  .search-documents-result {
    li {
      padding-bottom: 0.5rem;
      font-style: italic;
      list-style-type: none;
    }
  }
}

.constructor__body {
  padding: 0 2rem;
  background-color: inherit;

  .constructor__title {
    text-align: left;
  }

  .constructor__action {
    margin-top: 1rem;

    .button_link {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      text-decoration: none;
      color: #7c7c7c;
      font-size: 0.75rem;

      &:last-child {
        margin-bottom: 0;
      }
  
      &:hover {
        color: #5c5c5c;
      }

      @include media-breakpoint-up(sm) {
        font-size: 1rem;
      }
    }

    .icon {
      margin-right: 1rem;
    }
  }
}

.constructor__footer {
  padding-bottom: 3rem;
  text-align: center;
  background-color: inherit;
}

.constructor-online {
  background-image: url("/assets/images/constructor-online-bg.png");
  background-repeat: no-repeat;
  background-position: top 100px right 50%;

  .section__header {
    .section__title {
      max-width: 700px;
      margin: 0 auto;
    }

    .section__subtitle {
      max-width: 500px;
      margin: 1rem auto 0;
    }
  }
}

.constructor__useful {
  margin-bottom: 0;
  padding-bottom: 3rem;
  padding-top: 5rem;
  background-image: url("/assets/images/constructor-useful-bg.png");
  background-repeat: no-repeat;
  background-position: top 0px right 50%;

  @include media-breakpoint-up(md) {
    padding-bottom: 5rem;
  }

  .card__title {
    max-width: 350px;
  }

  .button_outlined {
    background-color: inherit;
  }
}

.constructor .section {
  border-radius: 40px;
  box-shadow: $shadow;
  overflow: hidden;
  background: #fff;
  padding: 0;
}

.constructor.full-screen {
  .section {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
    margin: 0;
    border-radius: 0;
  }

  .section__body {
    flex-grow: 1;
  }

  .card__actions {
    margin: 0 auto;
    padding: 1rem 0 0;
  }

  .section__footer {
    padding: 1rem 0;
  }

  .template-variables {
    max-height: 60vh;
  }
}

.document-selection {
  .section__title {
    margin-bottom: 1.5rem;
  }

  .card:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.buy-all, .get-document {
  .card__actions{
    flex-direction: column;

    .button{
      max-width: 280px;
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }

  .card__content {
    padding: 0rem;
    text-align: center;
  }

  .format-title {
    font-weight: 500;
  }

  .format-type {
    display: flex;
    max-width: 230px;
    margin: 0 auto;
    justify-content: space-between;
  }
}

.template-fill {
  .constructor__footer {
    padding: 1rem 2rem 1rem;
  }

  .checkbox {
    display: inline-block;
    margin-right: 3rem;
  }

  .card__action {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    margin-top: 2rem;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }

    .button {
      margin-right: 0;

      @include media-breakpoint-up(md) {
        margin-right: 4rem;
      }
    }

    .privacy-policy {
      text-align: center;

      @include media-breakpoint-up(md) {
        text-align: left;
      }
    }
  }
}

.scrollup{
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 1000;
  opacity: 0;
  transform: translateY(50px);
  transition: all .3s ease;

  @include media-breakpoint-up(lg) {
    bottom: 50px;
    right: 50px;
    transform: translateY(100px);
  }
}

.showBtn {
  opacity: 1;
  transform: translateY(0)
}

.card {
  padding: 2rem;

  .card__actions .button {
    white-space: inherit;
    padding: 0.5rem;

    @include media-breakpoint-up(sm) {
      white-space: nowrap;
      padding: 0 1.5rem;
    }
  }

  .card__title {
    font-size: 1.5rem;
  }

  .card__documents-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    list-style-type: none;
    padding-left: 0;

    li {
      width: 100%;
      padding-bottom: 0.5rem;

      @include media-breakpoint-up(md) {
        width: 48%;
      }
    }

    a {
      display: block;
      font-size: 1.125rem;
      line-height: 1.1;
    }
  }

  .fieldset {
    padding: 0;
    margin-bottom: 1rem;
  }

  .fieldset__title {
    margin-top: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;

    @include media-breakpoint-up(md) {
      font-size: 24px;
    }
  }

  .field .input {
    min-height: 0;
    padding: 0.5rem 1rem;
  }

  .input::placeholder,
  .input::-webkit-datetime-edit-fields-wrapper,
  .textarea::placeholder,
  .textarea::-webkit-datetime-edit-fields-wrapper {
    font-style: normal;
    font-size: 14px;
    color: #828689;

    @include media-breakpoint-up(md) {
      font-size: 16px;
    }
  }
}
.create-document {
  .constructor__header {
    margin-bottom: 0;
  }
  
  .constructor__footer {
    padding: 1rem 0 2rem;

    .button_link {
      display: flex;
      align-items: center;
      margin: 0 auto;
      padding: 0 3rem;
      font-size: 1.25rem;
      text-decoration: none;
      color: #7c7c7c;

      @include media-breakpoint-up(sm) {
        font-size: 1.5rem;
        padding: 0;
      }

      span {
        background-image: url("/assets/images/folder.svg");
        background-repeat: no-repeat;
        background-position: center left;
        background-size: 2rem;
        
        padding-left: 2.5rem;
        font-size: 1rem;
        text-align: left;

        @include media-breakpoint-up(sm) {
          background-size: 2.5rem;
          padding-left: 3rem;
          font-size: 1.25rem;
        }
      }

      &:hover {
        color: #5c5c5c;
      }
    }
  }

  .template-variables, .finished-document {
    max-height: 70vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 0.5rem;
  }

  .card__actions {
    margin: 2rem auto 0;
  }

  .divider-horizontal {
    margin: 1rem auto 0;
    max-width: 95%;
  }

  * {
    scrollbar-width: thin;
    scrollbar-color: #9d9d9d #d1d1d1;
  }
  
  // скроллбар для Chrome/Edge/Safari
  *::-webkit-scrollbar {
    height: 12px;
    width: 12px;
  }
  *::-webkit-scrollbar-track {
    background: #d1d1d1;
    border-radius: 5px;
  }
  // скроллбар для Firefox
  *::-webkit-scrollbar-thumb {
    background-color: #9d9d9d;
    border-radius: 5px;

    &:hover {
      background-color: #8d8d8d;
    }
  }
}

.document {
  span, p{
    line-height: 1.5;
  }
}
