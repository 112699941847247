.stepper-row {
  @include media-breakpoint-up(md) {
    background-image: url("/assets/images/arc-line.png");
    background-repeat: no-repeat;
    background-position: top 0px right 50%;
  }

  &__list {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    max-width: 1140px;
    margin: 0;
    list-style: none;
    counter-reset: counter;
    padding: 0 1rem;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      margin: 0 auto;
      align-items: inherit;
    }

    &.is-five-items {
      $margins: (
      'md': (
        '1': 65px,
        '2': 85px,
        '3': 95px,
        '4': 85px,
        '5': 65px,
        ),
      'lg': (
        '1': 30px,
        '2': 55px,
        '3': 65px,
        '4': 55px,
        '5': 30px,
        ),
      );

      .stepper-row__item {
        @each $breakpoint, $values in $margins {
          @include media-breakpoint-up($breakpoint) {
            @each $num, $margin in $values {
              &:nth-of-type(#{$num}) {
                margin-top: $margin;
              }
            }
          }
        }
      }
    }
  }

  .stepper-row__item {
    $lg-size: 70px;
    $size: 50px;

    display: flex;
    flex-direction: column;
    width: 150px;
    font-size: 0.875rem;
    align-items: center;
    text-align: center;


    @include media-breakpoint-up(lg) {
      width: 200px;
      font-size: 1.125rem;
    }

    &::before {
      content: counter(counter, decimal-leading-zero);
      counter-increment: counter;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      align-self: center;
      flex: 0 0 auto;
      width: $size;
      height: $size;
      font-weight: 500;
      font-size: 1.625rem;
      color: white;
      border-radius: 50%;
      background: linear-gradient(152.01deg, rgba(237, 24, 74, 0.7) -7.82%, rgba(238, 133, 73, 0.7) 105.71%);

      @include media-breakpoint-up(md) {
        margin: 0;
      }

      @include media-breakpoint-up(lg) {
        font-size: 1.875rem;
        width: $lg-size;
        height: $lg-size;
      }
    }

    &:not(:last-child) {
      padding-bottom: 60px;
      background-image: url("/assets/images/arrow_down.png");
      background-repeat: no-repeat;
      background-position: bottom 5px right 50%;

      @include media-breakpoint-up(md) {
        padding-bottom: 0;
        background-image: none;
      }
    }
  }
}
