
.text-underline::first-line {
  background-image: linear-gradient(178.97deg, rgba(237, 24, 74, 0.2) -7.82%, rgba(238, 133, 73, 0.2) 105.71%);
  background-position: 0 0.75em;
  background-repeat: no-repeat;
}

.text-nowrap {
  white-space: nowrap;
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .text#{$infix}-left { text-align: left !important; }
    .text#{$infix}-right { text-align: right !important; }
    .text#{$infix}-center { text-align: center !important; }
  }
}
