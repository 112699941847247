.page-header {
  text-align: center;

  @include media-breakpoint-up(md) {
    text-align: left;
  }

  &__title {
    margin-bottom: 1.25rem;

    @extend .text-underline;

    @include media-breakpoint-up(lg) {
      margin-bottom: 2.5rem;
    }
  }

  &__text {
    margin-top: 0;
    margin-bottom: 1.25rem;

    @include media-breakpoint-up(md) {
      max-width: 570px;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 1.875rem;
    }
  }

  &__body {
    @include media-breakpoint-up(md) {
      order: -1;
    }
  }

  &__image-wrap {
    @include media-breakpoint-up(md) {
      text-align: right;
    }
  }

  &__image {
    max-width: 300px;
    margin-bottom: 2rem;
    position: relative;

    @include media-breakpoint-up(md) {
      max-width: 100%;
      margin-bottom: 0;
    }

    @include media-breakpoint-up(xl) {
      max-width: none;
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2.5rem;

    @include media-breakpoint-up(md) {
      justify-content: flex-start;
      flex-direction: row;
    }
  }

  &__button {
    &:nth-child(1) {
      margin-bottom: 1rem;

      @include media-breakpoint-up(md) {
        margin-right: 1.25rem;
        margin-bottom: 0;
      }
    }
  }
}
